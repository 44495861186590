import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 0.72rem 0.46rem;
  > h2 {
    font-size: 0.3rem;
    color: #636363;
    font-weight: 800;
    position: relative;
    margin-bottom: 0.66rem;
    font-weight: bold;
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -0.2rem;
      width: 0.6rem;
      height: 0.04rem;
      background: #ff7c34;
    }
  }
  .sec {
    position: relative;
    .pic {
      width: 100%;
      z-index: 99;
      > img {
        width: 100%;
      }
      > h3 {
        font-size: 0.3rem;
        color: #636363;
        background: #fff;
        font-weight: bold;
        text-align: center;
        background-color: #f3f3f3;
        border-radius: 0.05rem;
        height: 0.86rem;
        line-height: 0.86rem;
      }
    }

    .txt {
      position: relative;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      > p {
        position: relative;
        line-height: 1.8;
        font-size: 0.18rem;
        color: #787878;
      }
      .p {
        > h4 {
          position: relative;
          font-size: 0.18rem;
          font-weight: 800;
          margin-top: 0.3rem;
          color: #636363;
        }
        > p {
          position: relative;
          line-height: 1.8;
          font-size: 0.18rem;
          color: #636363;
        }
      }
    }
  }
`;
export default function Banner({ title, subTitle, desc, img, content }) {
  return (
    <Wrapper id="tit">
      <h2 data-aos="fade-right">{title}</h2>
      <div className="sec">
        <div className="pic" data-aos="fade-down">
          <h3>{subTitle}</h3>
          <img className="img" src={img} alt="Banner" />
        </div>
        <div className="txt" data-aos="slide-up">
          <p>{desc}</p>
          {content.map((c, idx) => {
            return (
              <div key={idx} className="p">
                <h4>{c.title}</h4>
                {c.list.length === 1 ? (
                  <p>{c.list[0]}</p>
                ) : (
                  c.list.map((l, i) => (
                    <p className="l" key={i}>
                      {l}
                    </p>
                  ))
                )}
              </div>
            );
          })}
        </div>
      </div>
    </Wrapper>
  );
}
